* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  color: #FFFFFF;

}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; 

}

body {
  background-color: #191919;
  overflow-x: hidden;
}

body::before {
  content: "";
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh; 
  background-image: url('/src/images/background-1.png');
  filter: grayscale(0%) brightness(0%) contrast(201%) sepia(1%);
  background-size: 10%;
  z-index: -1;
}
.logo {
  height: 80px; 
  width: auto;
  margin-top: 1%;
}
.contact_us {
  float: right;
  margin-top: 2%;
  margin-right: 2%;
  width: 124px;
  height:39px ;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #FFFFFF;
  background: transparent;
  cursor: pointer;
}
.step_content_container {
  margin-left: 10px;
  margin-top: 150px; 
}

.step_container img {
  margin-top: -60px;
}

.step-title {
  font-size: 50px;
  font-weight: 700;
  color: #FFFFFF;
  margin-left: 100px;
  margin-bottom: 12px;
  letter-spacing: 5px;
}
.step-text {
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  width: 60%;
  line-height: 30px;
  margin-left: 100px;
  margin-bottom: 20px;
  letter-spacing: 1px;

}
.googlePlayTop{
  width: 150px;
  margin-left: 100px;

}
.appStoreTop{
  width: 150px;
}
.hand_img{
  width: 20vw;
  float: right;
  margin-right: 100px;
}
.about_container{
  background-color: #191919;
  width: 80%;
  margin: 0 auto;
  border-radius: 2px;
  margin-top: 20px;
  border: 1px solid #191919;
  padding-top: 30px;
}

.text-center-about-1 p{
  text-align: center;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 30px;
}
.text-center-about-2 p{
  text-align: center;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 30px;

}
.text-center-about-3 p{
  text-align: center;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 30px;
}
.firts_para_content p{
  width: 90%;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 30px;
}
.second_para_content p{
  width: 90%;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 30px;
}
.third_para_content p{
  width: 90%;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 30px;
}
.para_container {
  width: 100%;
  margin: 0 auto;
}
.image-container {
  overflow: hidden;
  width: 60%;
  padding-bottom: 24%; 
  float: right;
  margin-left: 60%;
  position: relative;
}
.mobile_First_img {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: -78%; 
  left: 0;
}
.para_content{
  padding-top: 120px;
  margin-left: 100px;
}
.para_content p {
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  color: #FFFFFF;
  width: 56%;
  height: 20%;
  padding: 0;
  margin-bottom: 30px;
}
.googlePlayBottom{
  width: 150px;
}
.appStoreBottom{
  width: 150px;
}
.bottom_box_container{
  position: relative;
  padding: 0% ;
}
.bottom_mobile_image img{
  width: 100%;
  margin-top: -20%;
  width: 60%;
  padding: 0;
  margin-left: 55%;
}
.bottom-container {
  position: absolute;
  padding: 0;
  margin-top: -15%;
  background-color: #191919;
  width: 100%;
  border: 1px solid #191919;
  padding-top: 20px;
}
.address{
  text-align: center;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 30px;

}
.contact{
  text-align: center;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 30px;
}
.privacy{
  text-align: center;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
}
.link_privacy{
  text-decoration: none;
}
.copy{
  font-family: 'Lato';
  text-align: center;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 20px;
} 

@media screen and (max-width: 500px) {
  .contact_us{
    margin-top: 20px;
    margin-left: 5%;
  }
  .step_content_container {
    padding: 0;
    margin-top: 80px;
  }
  .step_container img {
    width: 50%;
    margin-right: 10px;
    overflow: auto;
  }
  .step-title  {
    padding: 0;
    font-size: 40px;
    font-weight: 600;
    margin-left: 5%;
    letter-spacing: 1px;

  }
  .step-text {
    font-size: 16px; 
    font-weight: 400; 
    width: 50%; 
    line-height: 25px; 
    margin-left: 5%;
  }
  .step_content_container .googlePlayTop {
    width: 100px; 
    margin-left: 5%;
  }
  .step_content_container .appStoreTop {
    width: 100px;
  }
  .text-center-about-1 p {
    margin-bottom: 25px; 
  }
  .text-center-about-1 p {
    margin-bottom: 25px; 
  }
  .text-center-about-1 p {
    margin-bottom: 25px; 
  }
  .image-container {
  width: 100%;
  left: 20%;
  padding-bottom: 40%;
}
  .para_container{
    margin-left: 10%;
    padding: 0;
}
  .para_content{
    width: 100vw;
    margin: 0;
    padding: 0;
  }
  .para_content p {
    font-size: 16px;
    transform: translateY(-20%);
    margin-bottom: 20px;

  }
  .bottom_btn img {
    width: 100px;
    transform: translateY(-300%);
  }
  .bottom_btn a{
    display: block;
  }
  
  .bottom_mobile_image img{
    width: 100%;
    margin-left: 30%;
    margin-top: -55%;
}
  .bottom-container {
    margin-top: -26%;
    padding-top: 10px;
  }
  .address{
    font-size: 12px;
    margin-bottom: 20px;
    line-height: 20px;
  }
  .contact{
    font-size: 12px;
    margin-bottom: 20px;
    line-height: 20px;
}
  .privacy{
    font-size: 12px;
    margin-bottom: 20px;

  }
  .copy{
    font-size: 8px;
    margin-bottom: 5px;

  }
}

@media screen and (min-width: 501px) and (max-width: 800px) {
  .contact_us{
    margin-left: 10%;
  }
  .step_content_container {
    margin-top: 150px; 
    margin-left: 0;
  }
  .step_container img {
    width: 35%;
    margin-top: -50px;
    margin-right: 2%;
  }
  .step-title {
    font-size: 35px;
    font-weight: 500px;
    margin-left: 50px;
    letter-spacing: 2px;
  }
  .step-text{
    margin-left: 50px;
  }
  .googlePlayTop {
    width: 120px; 
    margin-left: 50px; 
  }
  .appStoreTop {
    width: 120px;
  }
  .hand_img {
    width: 22%;
    margin-right: 75px;
  }
  .text-center-about-1 p {
    margin-bottom: 30px; 
  }
  .text-center-about-2 p {
    margin-bottom: 30px; 
  }
  .text-center-about-3 p {
    margin-bottom: 30px; 
  }
  .image-container {
    width: 100%;
    padding-bottom: 38%; 

  }
  .mobile_First_img{
    margin-left: 31%;

  }
  .para_container {
    width: 90%;
  }
  .para_content{
    padding-top: 280px;
    margin: 0px;
  }
  .para_content p {
    width: 70%;
    margin-top: -20%;
    margin-bottom: 20px;
  }
  .bottom_btn img {
    width: 120px;
  }
  .bottom_box_container{
    margin-top: 30%;
    position: relative;
    padding: 0% !important;
  }
  .bottom_mobile_image img{
    width: 90%;
    margin-left: 32%;
    margin-top: -40%;
  }
  .bottom-container {
    margin-top: -22%;
  }
}

@media screen and (min-width: 801) and (max-width: 1200px) {
  
  .contact_us{
    margin-left: 20%;
  }
  .step-title {
    font-size: 45px; 
    font-weight: 600; 
  }
  .image-container {
    width: 100%;
    padding-bottom: 38%; 

  }
  .mobile_First_img{
    margin-left: 28%;

  }
  .para_container {
    width: 60%;
  }
  .para_content{
    padding-top: 280px;
    margin: 0px;
  }
  .para_content p {
    width: 70%;
    margin-bottom: 30px;
  }
  .bottom_btn img {
    width: 100px;
  }
  .bottom_box_container{
    margin-top: 30%;
  }
  
  .bottom_mobile_image img{
    width: 100%;
  }
  .bottom-container {
    top: 100%;
  }
}
@media screen and (min-width: 2000px) and (max-width: 2650px) {
  .logo {
    height: 100px; 
    width: auto;
    margin-top: 2%;
  }
  .contact_us {
    margin-top: 2%;
    margin-right: 2%;
  }
  .step_content_container {
    margin-left: 250px;
    margin-top: 250px; 
  }
  
  .step_container img {
    margin-top: -170px;
    margin-right: 250px;
  }
  .googlePlayTop{
    margin-left: 100px;
  
  }
  .about_container{
    width: 72%;
    margin-top: 100px;
  }
  .firts_para_content p{
    line-height: 60px;
  }
  .second_para_content p{
    line-height: 60px;
  }
  .third_para_content p{
    line-height: 60px;
    
  }
  .para_container {
    width: 72%;
  }
  .image-container {
    width: 80%;
    padding-bottom: 30.5%; 
  }
  
  .mobile_First_img {
    margin-left: 20%;
  }
  .para_content{
    padding-top: 590px;
    margin: 0;
  }
  .para_content p {
    margin-top: -30%;
    line-height: 60px;
    width: 50%;
  }
  .bottom_btn img {
    margin-top: 20px;
  }
  .bottom_box_container{
    margin-top: 340px;
    position: relative;
  }
  .bottom_mobile_image img{
    width: 60%;
    margin-left: 55%;
    margin-top: -25%;
  }
  .bottom-container {
    margin-top: -15%;
  }
  .address{
    line-height: 50px;
  }
  .contact{
    line-height: 50px;
  }
  .privacy{
    line-height: 50px;
  }
  
}
