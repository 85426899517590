*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    color: #FFFFFF;

 }
 .contact_container{
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(136, 128, 128, 0.2);
    overflow-y: scroll;
 }
.contact-container-details{
    margin-top: 10%;
    width: 600px;
    height: 680px;
    background-color: #000000;
    border-radius: 10px;
}
.contact-details-us{
    font-size: 20px;
    font-weight: 600;
    padding-left: 2%;
    padding-top: 5%;
    line-height: normal;
}
.contact_container img{
    margin: 0;
    width: 30%;
    height: auto;
    padding-left: 0%;
}
.name-text{
    font-weight: 500;
    font-size: 15px;
    padding-left: 40%;
    padding-bottom: 2%;
    margin-top: 2%;
}
.red-star{
    color: #FF0000;
    font-weight: 700;
    font-size: 10px;
    line-height: 22.5px;
}
.name-input{
    width: 306px;
    height: 47px;
    border-radius: 10px;
    margin-left: 40%;
    margin-bottom: 2%;
    padding-left: 3%;
    background-color: #E8E8E8;
    color: #000000;
}
.nameError{
    margin-left: 40%;
    color: #FF0000;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
}
.email-text{
    font-weight: 500;
    font-size: 15px;
    padding-left: 40%;
    padding-bottom: 2%;
}
.email-input{
    width: 306px;
    height: 47px;
    border-radius: 10px;
    margin-left: 40%;
    margin-bottom: 2%;
    background-color: #E8E8E8;
    padding-left: 3%;
    color: #000000;
}
.emailError{
    margin-left: 40%;
    color: #FF0000;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
}
.phone-text{
    font-weight: 500;
    font-size: 15px;
    padding-left: 40%;
    padding-bottom: 2%;
    margin-top: -22%;

}
.phone-input{
    width: 306px;
    height: 47px;
    border-radius: 10px;
    margin-left: 40%;
    margin-bottom: 2%;
    padding-left: 3%;
    background-color: #E8E8E8;
    color: #000000;
}
.phoneError{
    margin-left: 40%;
    color: #FF0000;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
}
.msg-text{
    font-weight: 500;
    font-size: 15px;
    padding-left: 40%;
    padding-bottom: 2%;
}
.msg-input{
    width: 306px;
    height: 130px;
    margin-left: 40%;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #E8E8E8;
    color: #000000;
    padding-left: 3%;
    padding-top: 3%;
    resize: none;
    overflow: auto;
    line-gap-override: 3%;
}
.msgError{
    margin-left: 40%;
    color: #FF0000;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
}
.send-btn{
    width: 196px;
    height: 37px;
    border-radius: 5px;
    font-family: Lato;
    font-weight: 700;
    font-size: 12px;
    background-color: #25691A;
    border-style: none;
    margin-left: 50%;
    margin-top: 4%;
    margin-bottom: 25px;
    cursor: pointer;

}
.success-message{
    margin-left: 40%;
    font-weight: bold;
    font-size: 18px;
}
.icon{
    margin-top: -10%;
}
.close-modal{
    width: 22px;
    cursor: pointer;
    padding-left: 95%;
    padding-top: 0;
}
@media screen and (max-width: 500px) {
    .contact-container-details{
        margin-top: 20%;
        width: 350px;
        height: 580px;
    }
    .contact-details-us{
        font-size: 15px;
        font-weight: 600;
        padding-left: 2%;
        padding-top: 5%;
    }
    .flower_Image{
        width: 100%;
        margin-left: 2%;
    }
    
    .name-text{
        font-weight: 600;
        font-size: 12px;
        padding-left: 40%;
        padding-bottom: 2%;
    }
    .red-star{
        font-weight: 700;
        font-size: 10px;
        line-height: 22.5px;
    }
    .name-input{
        width: 155px;
        height: 35px;
        border-radius: 5px;
        margin-left: 40%;
        margin-bottom: 2%;
    }
    .email-text{
        font-weight: 600;
        font-size: 12px;
        padding-left: 40%;
        padding-bottom: 2%;
    }
    .email-input{
        width: 155px;
        height: 35px;
        border-radius: 5px;
        margin-left: 40%;
        margin-bottom: 2%;
        background-color: #E8E8E8;

    }
    .phone-text{
        font-weight: 600;
        font-size: 12px;
        padding-left: 40%;
        padding-bottom: 2%;
        margin-top: -22%;
    }
    .phone-input{
        width: 155px;
        height: 35px;
        border-radius: 5px;
        margin-left: 40%;
        margin-bottom: 2%;
        background-color: #E8E8E8;

    }
    .msg-text{
        font-weight: 600;
        font-size: 12px;
        padding-left: 40%;
        padding-bottom: 2%;
    }
    .msg-input{
        width: 160px;
        height: 102px;
        margin-left: 40%;
        border-radius: 5px;
        background-color: #E8E8E8;
    }
    .send-btn{
        width: 96px;
        margin-left: 50%;
    }
    .icon{
        margin-top: -10%;
    }
    .close-modal{
        cursor: pointer;
        padding-left: 90%;
        padding-top: 0;
    }
}
@media screen and (min-width: 2000px) {
    .contact-container-details{
        width: 900px;
        height: 850px;
    }
    .flower_Image{
        margin-left: 2%;
        margin-top: -10%;
    }
    
    .name-text{
        margin-top: 5%;
    }
    
    .phone-text{
        margin-top: -11%;
    }
    .msg-input{
        margin-bottom: 20px;
    }
    .send-btn{
        width: 152px;
        height: 35px;
        border-radius: 5px;
        font-family: Lato;
        font-size: 10px;
        margin-left: 50%;
    }
    .icon{
        margin-top: -6%;
    }
    .close-modal{
        padding-left: 95%;
    }

}
